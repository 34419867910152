import 'select2'
import '../backend/codebase/app.js'
import 'flickity/dist/flickity.pkgd.min'
import  'inputmask'
import 'inputmask/dist/bindings/inputmask.binding'


jQuery($ =>
{
    // =====================================================
    // Events Carousel
    // =====================================================

    $('.events-slider').flickity({
        contain: true,
        pageDots: false,
        autoPlay: true,
    })


    // =====================================================
    // Select2 Dropdowns
    // =====================================================

    $('.js-select2').select2()


    // =====================================================
    // Masked Phone Inputs
    // =====================================================

    jQuery('.js-masked-phone').mask('999 999 99 99')


    // =====================================================
    // Venue nav tabs - Fix (close & open)
    // =====================================================

    let $venue_tab_active = $('.venue .nav-tabs .nav-item')

    $venue_tab_active.click(e =>
    {
        let $this = $(e.currentTarget)
        let tab_active = 'tab_active'

        $this.siblings().removeClass(tab_active)

        if(!$this.toggleClass(tab_active).hasClass(tab_active))
        {
            $this.find('.nav-link').toggleClass('active')
            $this.closest('.venue').find('.tab-content .tab-pane.active').toggleClass('active show')
        }
    })


    // =====================================================
    // Car & Public Transport - Search directions
    // =====================================================

    $('.departure_point_btn').click(e =>
        open_directions(e)
    )

    $('.departure_point').keyup(e =>
    {
        if(e.keyCode == 13)
        {
            open_directions(e)
        }
    })

    function open_directions(e)
    {
        let $this = $(e.currentTarget)
        let $input = $this.closest('.directions_search').find('input')

        if($input.val())
        {
            let url = $input.data('url').replace('{departure_point}', $input.val());

            let win = window.open(url, '_blank')

            if(win)
            {
                win.focus()
            }
            else
            {
                alert('Bitte erlauben Sie Pop-ups für diese Webseite in Ihren Browser-Einstellungen.')
            }
        }
    }
})
